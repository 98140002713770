import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { query, where, collection, doc, getFirestore, limit } from "firebase/firestore"
import { getAnalytics } from "firebase/analytics"
import { useFirestoreDocument, useFirestoreQueryData, useFirestoreDocumentMutation } from "@react-query-firebase/firestore";
import { useAnalyticsCustomLogEvent } from "@react-query-firebase/analytics";
import { useAuthDeleteUser } from "@react-query-firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBdnLLuSfMkx7VA3DX2hogrn2IQc10Ygg4",
  authDomain: "freeguides-prod.firebaseapp.com",
  databaseURL: "https://freeguides-prod.firebaseio.com",
  projectId: "freeguides-prod",
  storageBucket: "freeguides-prod.appspot.com",
  messagingSenderId: "964919675481",
  appId: "1:964919675481:web:97c9d2f0d1ba758fdfbf48",
  measurementId: "G-YEF0QPYG6L",
  //serviceAccountID: "algos-backend@freeguides-prod.iam.gserviceaccount.com"
};


const fb = initializeApp(firebaseConfig)
const firestore = getFirestore(fb)

export const analytics = getAnalytics(fb)
export const auth = getAuth(fb);

export const getTour = (id) => {
  const ref = doc(firestore, "tours", id)
  const tourDoc = useFirestoreDocument(["tours", id], ref)
  return tourDoc
}

export const getTours = async () => {
  const col = collection(firestore, "tours")
  const ref = query(col, where("ApprovalStatus", "==", "approved"), where("Deleted", "==", false), where("promotionType","==", { quality: 'super'}), limit(50));
  const q = useFirestoreQueryData(["tours", { ApprovalStatus: 'approved', Deleted: false, promotionType: { quality: 'super '} }], ref)
  
  setTimeout(() => {
    var stops = []
    var locations = []
    q.data.map(val => {
      stops.push(val.Stops[0].address)
      locations.push(val.Stops[0].location)
    })
  }, 500)
  
  return q
}

export const getGuide = (id) => {
  const ref = doc(firestore, "users", id)
  const user = useFirestoreDocument(["users", id], ref)
  return user
}

export const getGuides = (field,op,value) => {
  var queries = {}
  queries[field] = value

  const col = collection(firestore, "users")
  const ref = query(col, where(field,op,value))
  const q = useFirestoreQueryData(["users", queries ],ref)
  return q
}

export const getRec = (id) => {
  var queries = { placeId: id  }

  const col = collection(firestore, "recommendations")
  const ref = query(col, where("placeId","==",id))
  const q = useFirestoreQueryData(["recommendations", queries ],ref)
  return q
}

export const updateTour = (id) => {
  const ref = doc(firestore,"tours",id)
  const mutation = useFirestoreDocumentMutation(ref, { merge: true })
  return mutation
}

export const deleteUser = () => {
  const mutation = useAuthDeleteUser(auth);
  return mutation
}


