import React,{ useState, useEffect } from 'react';
import { StyleSheet, View, Text, Button, ActivityIndicator } from 'react-native';
import QRCode from "react-qr-code";
import { getTour, updateTour, analytics } from '../helpers/fbHelper'
import success from "../../assets/success.gif"
import Referral from "../components/utils/referral"
import Screenshot from "../../assets/screenshot_test.png"


const customers = ["RadissonBlu@FreeGuides.com.au,","FreeGuides-Adina@abyadina.com,","SpicersPottsPoint@FreeGuides.com.au,","Freeguides-HarbourRocks@accor.com,","FreeGuides-DarlingHarbour@novotel.com.au,","FreeGuides@ibis.com.au,","MövenpickMelbourne@FreeGuides.com.au,","TheSebelManly@FreeGuides.com,","FreeGuides@lylo.com,","FreeGuides-Queenstown@LyLo.com,","FreeGuides-Christchurch@lylo.com,","FreeGuides-brisbane@lylo.com,","Capella-Sydney@FreeGuides.com.au,","BoltonHotelWellington@FreeGuides.com,","CrownePlazaCoogee@FreeGuides.com,","HolidayInnPottsPoint@FreeGuides.com,","SwissôtelSydney@FreeGuides.com,","MetroMarlow@Freeguides.com.au,","FreeGuides-DarlingSquare@novotel.com.au,","PullmanSydneyHydePark@FreeGuides.com,","FreeGuides@castlereagh.com.au,","CrownePlazaCanberra@FreeGuides.com,","Novotel-Canberra@FreeGuides.com.au,","rm1-yellowsquare@FreeGuides.com,","fi1-yellowsquare@FreeGuides.com,","mi1-yellowsquare@FreeGuides.com,","patrick@dialogstudio.co.nz,","InterContinental-Double-Bay@freeguides.com,","FreeGuides@songhotels.com,","CrownePlazaMelbourne@FreeGuides.com.au,","Crowne-Plaza-Rome@Freeguides.com.au"]

const UpdateVivid = (props) => {
  //const vividTours = ['0699cJbL1gSLxoYLkJkp','3WQ4DJ7YW70QS7d3llPA','A9kyx5WGSP8CENhGYUBb','D2tiacpr66AUmNA1foxk','IswPt8MQI91z9FmOxxwx','NkgirkOWEHHiyVrC17JP','hqhvigof0vdI42gfmZdN','jmiUp0kh5HVEBh5nZ6kU','qwWVdSjR9kWx3F0iiyMe','rtFjJDzc5EF8Mje0V4gA','uQYxZniZaJ8AGQU1O8LK','wJFJsvNPBjBhz7dXSqgZ','x5TJckHmecxbpxU2Ksie']
  //const vividTours = ['VJAQ4m96wcaCulr1dIfq']
  //const org = getTour('9ZoER6OpuY0qiNRdSZtD')

  const toursToUpdate = vividTours.map(val => {
    return updateTour(val)
  })

  if(org.isLoading) {
    return (
      <View style={styles.container}>
        <ActivityIndicator size="large"/>
      </View>
    );
  }

  var i = 0 
  const stops = org.data.data().Stops
  for(var t of toursToUpdate) {
    t.mutate({
      Stops: stops
    })
  }

  return (
    <View>
      <Text>DONE!!!</Text>
    </View>
  )
}

const Test = ({ navigation, screenName }) =>{
  const url = new URLSearchParams(window.location.search);
  const id = url.get('tourId') ??  'CX8DU5InvKtEoSh3f16V'; //'VJAQ4m96wcaCulr1dIfq' 
  const tour = getTour(id)

  //const tour = { isLoading: false }
  const index = url.get('stopId') ?? 0
  var [showSuccess,setSuccess] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      var cards = document.getElementById('activities__card')
      console.log(cards)
    },5000)
  }, [])

  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  const success = (pos) => {
    const crd = pos.coords;

    console.log("Your current position is:");
    console.log(`Latitude : ${crd.latitude}`);
    console.log(`Longitude: ${crd.longitude}`);
    console.log(`More or less ${crd.accuracy} meters.`);
  }

  const error = (err) => {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  const getPlace = async (id) => {
    const resp = await fetch(`https://places.googleapis.com/v1/places/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'X-Goog-Api-Key': 'AIzaSyB7fofXXxvV2vcvADFR398kS9BzTu-7usU',
            'X-Goog-FieldMask': 'id,displayName,formattedAddress,photos,location,rating,regularOpeningHours,priceLevel,types,userRatingCount,websiteUri,googleMapsUri,businessStatus,takeout,delivery,dineIn,curbsidePickup,reservable,servesBreakfast,servesLunch,servesDinner,servesBeer,servesWine,servesBrunch,servesVegetarianFood'
        }
    });

    var json = await  resp.json()
    console.log(json)

    return resp
  }

  if(tour.isLoading) {
    return (
      <View style={styles.container}>
        <ActivityIndicator size="large"/>
      </View>
    );
  }

  const snapshot = tour.data;
  const { Name: name, Stops: stops, Guide: guide, Owner: owner, persona, guideProfilePic } = snapshot.data() ?? ''
  var stop = stops[index] ?? { media: [] }
 
  navigator.geolocation.getCurrentPosition(success, error, options);
  getPlace("ChIJjQeFbCSuEmsR9yUKPtbHizE")

  var place = {
    displayName:  { text: "The Sebel Sydney Manly Beach", languageCode: "en" },
    formattedAddress: "13 S Steyne, Manly NSW 2095, Australia",
    id: "ChIJe06Frg-rEmsRVsim-Uh9Jdg",
    location: { latitude: -33.7994493, longitude: 151.2894172 },
    rating: 4.2
  }

  /*
  var media = stop.media.filter(val => val.type === 'referral')[0]
  var content = media?.content ?? []
  <img src={Screenshot}/>
  <div style={{position: 'absolute', top: '50%', left: 5, width: '95%'}}>
    <div data-gyg-href="https://widget.getyourguide.com/default/activities.frame" data-gyg-locale-code="en-US" 
        data-gyg-widget="activities" data-gyg-number-of-items="20" data-gyg-cmp="YellowSquare Rome" 
        data-gyg-partner-id="KSIQTMD" data-gyg-q="Rome Italy">
      <span>Powered by <a target="_blank" rel="sponsored" href="https://www.getyourguide.com/-t544302">GetYourGuide</a></span>
    </div>
  </div>
  */

  return (
    <View style={styles.container}>
      <div style={{ width: 500, height: 300, backgroundColor: 'white', padding: 20, display: 'flex', flexDirection: 'column', gap: 10 }}>
        <b>Quick Information</b>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridTemplateRows: '80px 80px', gap: 10 }}>
          <div style={{gridArea: '1 / 1 / 1 / 3', backgroundColor: 'lightgray', borderRadius: 10, padding: 10}}>
            <b>Check-in/out Times</b> 
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <div style={{width: 80}}>Check-in 03:00 AM</div>
              <div style={{width: 80}}>Check-out 11:00 PM</div>
            </div>
          </div>
          <div style={{ backgroundColor: 'lightgray', borderRadius: 10, padding: 10}}>
            Two
          </div>
          <div style={{backgroundColor: 'lightgray', borderRadius: 10, padding: 10}}>
            Three
          </div>
        </div>
      </div>
    </View>
  )
}

export default Test

const styles = StyleSheet.create({
  container: {
    flex: 1,
    //justifyContent: 'center', 
    backgroundColor: '#fcfefc'
  },
  title: {
    width: '100%',
    textAlign: 'left',
    fontFamily: 'quicksand_bold',
    fontSize: 17
  },
  sheetContainer: {
    width: '100%',
    justifyContent: 'center',
  },
  video: {
    width: '100%',
    height: 300
  },
  video: {
    width: '100%',
    paddingBottom: 10
  }
});
