import React,{ useState, useEffect } from 'react';
import { StyleSheet, View, Text, Button, ActivityIndicator } from 'react-native';
import QRCode from "react-qr-code";
import { getTour, getRec, analytics } from '../helpers/fbHelper'
import success from "../../assets/success.gif"
import Referral from "../components/utils/referral"
import {createComponent} from '@lit-labs/react';
import { PlaceOverview as PlaceOverviewWC } from '../components/place_overview/place_overview.js';
import { Marker } from '@react-google-maps/api';
import { GoogleMap, useJsApiLoader  } from '@react-google-maps/api';

const PlaceOverview = createComponent({
  tagName: 'gmpx-place-overview',
  elementClass: PlaceOverviewWC,
  react: React,
});

const PlaceCard = ({ navigation, screenName }) =>{
  const url = new URLSearchParams(window.location.search);
  const id = url.get('placeId') ?? 'ChIJk8CcRwivEmsROPWHqq2Qlv0'; //'VJAQ4m96wcaCulr1dIfq' 
  const directions = url.get('url') ?? 'https://google.com'
  const rec = getRec(id)
  const iframe = window.frameElement 
  var [details, setDetails] = useState({  
    insight: url.get('insight') ?? null,
    brand: '#' + (url.get('brandColor') ?? '60BE83'),
    textColor: '#' + (url.get('textColor') ?? 'FFFFFF'),
    loading: true
  }) 

  useEffect(() => {
    console.log('loading =',rec.isLoading)
    if(!rec.isLoading) {
      var result = { ... details, loading: false }
      if(rec.data.length > 0) {
        var doc = rec.data[0]

        result = { ...result, ...doc.details }
      } 

      setDetails(result)
    }
  }, [rec.isLoading])


  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyB7fofXXxvV2vcvADFR398kS9BzTu-7usU",
    version: "beta"
  })


  if(!isLoaded || rec.isLoading || details.loading) {
    return (
      <View style={styles.container}>
        <ActivityIndicator size="large"/>
      </View>
    );
  }


  var place = id
  var autoFetch = true
  const snapshot = rec.data;

  if(snapshot.length > 0) {
    var doc = snapshot[0]
    if(doc.data && Object.keys(doc.data).length > 0) {
      place = { ...doc.data, 
        isOpen: () => { return true },
        "displayName": doc.data?.displayName?.text ?? "",
        "hasTakeout": doc.data?.takeout ?? null,
        "hasDelivery": doc.data?.delivery ?? null,
        "hasDineIn": doc.data?.dineIn ?? null,
        "hasCurbsidePickup": doc.data?.curbsidePickup ?? null, 
        photos: doc.data?.photos.slice(0,2)
      }
    }
  } else{
    autoFetch = false
  }

  console.log('PAST LOADING!', autoFetch)
  console.log(place)

  /*
 var test = "https://places.googleapis.com/v1/places/ChIJI2W29RlNFmsRxuGL6y2hwGc/photos/AUy1YQ1BIOq4qrbKf_9QxE8d3RNWdGr2AwjrbkQ9ZieBbLOqT5XR898_apJe9HJ2v7-VmI3Tuq4WKYz4st8RbZLtEtbWbuIauAVqWNnFw0GJS0XaR-XHV0XTYv9IOhvd01VnZXnpurAaD2HVJqUnGnxv-n5OI69CyzT9vGZcswSfBoNgfoDD8fafWp9KxBhVZ-twO-tajbD-05JSBY6-3N4nQKG-PupAULGNyWY9vbInW2IcncckuZocG0LgfLbqSRsBodTwAB6CqdsdKpMVDMyrjAn6Maa6pJjR0nDBQOduXjs/media?maxWidthPx=720&key=AIzaSyB7fofXXxvV2vcvADFR398kS9BzTu-7usU"
 var test2 = "https://lh3.googleusercontent.com/places/ANXAkqH2-YpkH8EZYYEVp1lTNqeucSf0P1ngN5YjpHuc6d7F9DdYGYrABOSOcUFUg14-juZ5fX5ChZZ7H__mwPb7XSofIIEzPbOeUQ=s4800-w720"

  return (
    <View style={styles.container}>
      <div onLoad={e => console.log(e)} style={{
          backgroundImage: `url(${test2})`,
          width: 720,
          height: 200
        }}
      />
    </View>

  )
  */

  return (
    <View style={styles.container}>
      <View style={{width: '90%', height: '95%', backgroundColor: 'white', justifyContent: 'center', display: 'flex' }}>
        <PlaceOverview
          size="custom"
          autoFetchDisabled={autoFetch}
          //place="ChIJk8CcRwivEmsROPWHqq2Qlv0"
          //travelMode="driving"
          //travelOrigin={{ lat: -33.9700369, lng: 150.9005777 }}
          place={place}
          google-logo-already-displayed
        />
        { 
          details?.insight && (
            <Text style={{...styles.text, marginLeft: 20, marginTop: -20}}>{details.insight}</Text>
          )
        }
        <View style={{display: 'flex', flexDirection: 'row', marginTop: details?.insight ? 0  : -30}}>
          <a
            style={{...styles.linkContainer, backgroundColor: details?.brand }}
            href={"https://www.google.com/maps/search/?api=1&query=Sydney&query_place_id=" + id}
            target="_blank">
            <Text style={{...styles.linkText, color: details?.textColor }}>
              Directions
            </Text>
          </a>
          {
            details?.actionValue && (
              <a
                style={{...styles.linkContainer, backgroundColor: 'white', border: '1px solid lightgray'}}
                href={ details.actionValue }
                target="_blank">
                <Text style={{...styles.linkText, color: 'gray'}}>
                  { details.actionTitle }
                </Text>
              </a>
            )
          }
        </View>
      </View>
    </View>
  )
}

export default PlaceCard

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center', 
  },
  title: {
    width: '100%',
    textAlign: 'left',
    fontFamily: 'quicksand_bold',
    fontSize: 17
  },
  sheetContainer: {
    width: '100%',
    justifyContent: 'center',
  },
  video: {
    width: '100%',
    height: 300
  },
  video: {
    width: '100%',
    paddingBottom: 10
  },
  linkContainer: {
    marginTop: 10,
    marginBottom: 10,
    width: "50%",
    minHeight: 40,
    marginLeft: 20,
    backgroundColor: "#60be83",
    borderRadius: 5,
    minHeight: 40,
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    textDecorationLine: "none",
    paddingHorizontal: 10,
  },
  text: {
    color: '#757575',
    fontFamily: 'Quicksand',
    fontStyle: 'italic',
    fontSize: 14
  },
  linkText: {
    color: "white",
    fontSize: 12,
    textDecorationLine: "none",
    textAlign: "center",
    fontFamily: 'quicksand_bold'
  },
});


var testPlace = {
  "id": "ChIJjQeFbCSuEmsR9yUKPtbHizE",
  "types": [
    "restaurant",
    "food",
    "point_of_interest",
    "establishment"
  ],
  "formattedAddress": "160 Thomas St, Haymarket NSW 2000, Australia",
  "location": {
    "latitude": -33.8809496,
    "longitude": 151.2036595
  },
  "rating": 4.1,
  "googleMapsUri": "https://maps.google.com/?cid=3570166852599031287",
  utcOffsetMinutes: 660,
  "regularOpeningHours": {
    "openNow": true,
    "periods": [
      {
        "open": {
          "day": 0,
          "hour": 10,
          "minute": 0
        },
        "close": {
          "day": 0,
          "hour": 21,
          "minute": 30
        }
      },
      {
        "open": {
          "day": 1,
          "hour": 10,
          "minute": 0
        },
        "close": {
          "day": 1,
          "hour": 21,
          "minute": 30
        }
      },
      {
        "open": {
          "day": 2,
          "hour": 10,
          "minute": 0
        },
        "close": {
          "day": 2,
          "hour": 21,
          "minute": 30
        }
      },
      {
        "open": {
          "day": 3,
          "hour": 10,
          "minute": 0
        },
        "close": {
          "day": 3,
          "hour": 21,
          "minute": 30
        }
      },
      {
        "open": {
          "day": 4,
          "hour": 10,
          "minute": 0
        },
        "close": {
          "day": 4,
          "hour": 21,
          "minute": 30
        }
      },
      {
        "open": {
          "day": 5,
          "hour": 10,
          "minute": 0
        },
        "close": {
          "day": 5,
          "hour": 21,
          "minute": 30
        }
      },
      {
        "open": {
          "day": 6,
          "hour": 10,
          "minute": 0
        },
        "close": {
          "day": 6,
          "hour": 21,
          "minute": 30
        }
      }
    ],
    "weekdayDescriptions": [
      "Monday: 10:00 AM – 9:30 PM",
      "Tuesday: 10:00 AM – 9:30 PM",
      "Wednesday: 10:00 AM – 9:30 PM",
      "Thursday: 10:00 AM – 9:30 PM",
      "Friday: 10:00 AM – 9:30 PM",
      "Saturday: 10:00 AM – 9:30 PM",
      "Sunday: 10:00 AM – 9:30 PM"
    ],
    "nextCloseTime": "2025-03-10T10:30:00Z"
  },
  "businessStatus": "OPERATIONAL",
  "priceLevel": "PRICE_LEVEL_INEXPENSIVE",
  "userRatingCount": 1679,
  "displayName": "Chinese Noodle House",
  "hasTakeout": true,
  "hasDelivery": true,
  "hasDineIn": true,
  "hasCurbsidePickup": true,
  "isReservable": false,
  "servesBreakfast": true,
  "servesLunch": true,
  "servesDinner": true,
  "servesBeer": false,
  "servesWine": false,
  "photos": [
    {
      "name": "places/ChIJjQeFbCSuEmsR9yUKPtbHizE/photos/AUy1YQ30USplsLZyYTqvm90z_QLxho2BxPg8WPOYoESSg-tO_y_f2tKnhba8S1VwMl7sStaqENx9pEQpnUkwGhYZk2KVl0VQv2TWNtm2YXJwovFnhzbhl5tnvfimYLNGdH7d47HsYra2ZOm7MSL2thALl9EwYjI6qDXra6ID_v-wCALudZuSB-G7AYAFYtu5BsefjTrk2U2BqkFrpEA1I55P8BGj1JaLmZLl1_uELQiji3W9g3JHLol8A148OY7Jh8OoSPAlLR5eQXlFLi3D4OLTCjFGSDX4VhAfOplbe4s4SwhjBw",
      "widthPx": 1440,
      "heightPx": 811,
      "authorAttributions": [
        {
          "displayName": "Chinese Noodle House",
          "uri": "https://maps.google.com/maps/contrib/113793979777842115814",
          "photoUri": "https://lh3.googleusercontent.com/a-/ALV-UjWlq572u0DFAPc1dGfuX3iQ6gI0KfaQYI51zyYcv4UU4wycy7U=s100-p-k-no-mo"
        }
      ],
      "flagContentUri": "https://www.google.com/local/imagery/report/?cb_client=maps_api_places.places_api&image_key=!1e10!2sAF1QipNJuQw8IYiWqic5G5Rro4QhKYdPM9USZtfKMXSP&hl=en-US",
      "googleMapsUri": "https://www.google.com/maps/place//data=!3m4!1e2!3m2!1sAF1QipNJuQw8IYiWqic5G5Rro4QhKYdPM9USZtfKMXSP!2e10!4m2!3m1!1s0x6b12ae246c85078d:0x318bc7d63e0a25f7"
    },
    {
      "name": "places/ChIJjQeFbCSuEmsR9yUKPtbHizE/photos/AUy1YQ1WKCoq-vnfoohDyHvY1LI8b-YmeKne2wopScnPJRsDw_hZXWJNPJIpWXBsw19eZ28kOyXYhnSoOB6xboEfoOX0NykBtn4dITJPfqZ5LV_gPEXRFa9GGzjd_jHm8BwCgndtr7DjI9DCFBMhbRPsbl0FVSAqK9l_n_Z77m5Xkj01jcSz6Tj9rWPkGsW52lSCeUac7FLfP55bHExAXCWxcO0BG5PguCFbxAi7CBYW2Ulv0U7Ia3Ckmky2nMAF-BmQPIHyf8vf4scbFlTogsxDa6UUNOv67_nOwAXSW64T5NLhXw",
      "widthPx": 527,
      "heightPx": 647,
      "authorAttributions": [
        {
          "displayName": "Chinese Noodle House",
          "uri": "https://maps.google.com/maps/contrib/113793979777842115814",
          "photoUri": "https://lh3.googleusercontent.com/a-/ALV-UjWlq572u0DFAPc1dGfuX3iQ6gI0KfaQYI51zyYcv4UU4wycy7U=s100-p-k-no-mo"
        }
      ],
      "flagContentUri": "https://www.google.com/local/imagery/report/?cb_client=maps_api_places.places_api&image_key=!1e10!2sAF1QipPb2FZrkFPn0A4boprxaLhb1R96Z_yG0x1Q4qpd&hl=en-US",
      "googleMapsUri": "https://www.google.com/maps/place//data=!3m4!1e2!3m2!1sAF1QipPb2FZrkFPn0A4boprxaLhb1R96Z_yG0x1Q4qpd!2e10!4m2!3m1!1s0x6b12ae246c85078d:0x318bc7d63e0a25f7"
    },
    {
      "name": "places/ChIJjQeFbCSuEmsR9yUKPtbHizE/photos/AUy1YQ0qqMWTm9Vj3FUtqE_dbkJdM8sgHcgTXrKi1_Dom3VYYaTbkGHwwgkB3jMZNH-RY-lGuE_X3h7tyY45En24GEzqRrVTyuOMNJ_XaYsVRrlDJOCfPM-0oPwiyKujyE-wepNRlcdORWQbXigl5VNu7CyFj4sSZa0Ux3OBj7vRgECQ38V4X9T2BXmfary-el-37CzRWYlA-XjU32A2aY6fzr5mKF4WbT2VI_rm1R05dW4mL3i0rJuC47pGuXe5d6-7arNKfQJzJA2cE38NGZgp7Xbn9z4o9RpYd2nZCKUQUXUTDiiM4Yzljau4a-gjSuoC-4EpiIOG3cvZ9Bwjo1G04eMHWC1ZZzxwAVdzbdfUE6YzDYbfyJ2FsASndIAmV2s08CZb2rUUSDR5E8tbQKHoITLdG4t4f24",
      "widthPx": 3072,
      "heightPx": 4080,
      "authorAttributions": [
        {
          "displayName": "Warren Malone",
          "uri": "https://maps.google.com/maps/contrib/105724775494744571892",
          "photoUri": "https://lh3.googleusercontent.com/a-/ALV-UjUkop5NX9CxBvqZydD3ir_eQaGKUVVAqBbuqP5PPVFm895kdAk2vA=s100-p-k-no-mo"
        }
      ],
      "flagContentUri": "https://www.google.com/local/imagery/report/?cb_client=maps_api_places.places_api&image_key=!1e10!2sCIHM0ogKEICAgIDn0ffsIg&hl=en-US",
      "googleMapsUri": "https://www.google.com/maps/place//data=!3m4!1e2!3m2!1sCIHM0ogKEICAgIDn0ffsIg!2e10!4m2!3m1!1s0x6b12ae246c85078d:0x318bc7d63e0a25f7"
    },
    {
      "name": "places/ChIJjQeFbCSuEmsR9yUKPtbHizE/photos/AUy1YQ3yKCkhVVIignVaep_74XWbTC6-QeoEXlivIMerATWNIu-45mH-pp2XPrxCE0WoHU8lCRLnxkUFWlWJS0aLdb7AacmZvnn_ftGnOwooZxGUJ9n68Acd9-yIsL1YQQezQeYtVt-k6Ukcarx_7-zQp4j85aBV8cK0roGwwwRTG9EZFsutMz4b_ZuMIKQXH2NuQsIEDa0gM28s56WmMCQx_2dGk8-gf0bwiu7Ci4qS3eOWUmaurbge3txbliQ8SlcDysLxYyVfest6bmVwHV-cT6gQsJ_PuiZMzK7CctLKISm0rnIfi7xOe1DKjwegRq_GC4Z1mci9rP_RReC7rxrikyP42FlokQ1rtpRLn-IU1kg7F7PrrV1fqFPOKTRFAHU9E3TWOSXdDZ2U-StGrXTUmk6lP8ZaKTSDl8Km",
      "widthPx": 3926,
      "heightPx": 2944,
      "authorAttributions": [
        {
          "displayName": "zoey",
          "uri": "https://maps.google.com/maps/contrib/112967713476973642713",
          "photoUri": "https://lh3.googleusercontent.com/a/ACg8ocIIjXDUj73S24a-k6rcz1gEL1CJBDgvvEHNFIB2Ng3UAdQjtA=s100-p-k-no-mo"
        }
      ],
      "flagContentUri": "https://www.google.com/local/imagery/report/?cb_client=maps_api_places.places_api&image_key=!1e10!2sCIHM0ogKEICAgMCA9_7kqAE&hl=en-US",
      "googleMapsUri": "https://www.google.com/maps/place//data=!3m4!1e2!3m2!1sCIHM0ogKEICAgMCA9_7kqAE!2e10!4m2!3m1!1s0x6b12ae246c85078d:0x318bc7d63e0a25f7"
    },
    {
      "name": "places/ChIJjQeFbCSuEmsR9yUKPtbHizE/photos/AUy1YQ2FUFzrhVUbhikmd1Hw3ivI1lh4yY2Tmsc3pFi3yJE-JwBPLWa-sF-pBzcUi9S3wjmia7UtRtq2B5ZBMpVZg1-zJQCWc36Ov_AdRRVfHf7ZrauKPc7cv-4IeH1yVaaKH_rCNMzXXl-9DV-wLmTyDhtGOCpqBJUtlAsEBAL97-xF6N1ZK_xxWprsZY5cHQ5WWM8xnq2oto5UVZPntuUkyPKvEcFy9F50fsWsBBV9QcmIMMyw80qfn71x1VQeilbfYvfhjkciRRBaT3n87L_vQMkSWppXyTCIWPU5idzDRyHyyKl8VTW1t9ofKS-TfW-zkRF88atZJNI535md2Ch14RZbxGyr2RrumkBOrqbk7_Gp_WoVjY4_QMYK5ass0MmKjglqnmDk5TPihfaD28XiR7t1rMpF7knj",
      "widthPx": 3000,
      "heightPx": 4000,
      "authorAttributions": [
        {
          "displayName": "Alex Chen",
          "uri": "https://maps.google.com/maps/contrib/103423389317350174495",
          "photoUri": "https://lh3.googleusercontent.com/a-/ALV-UjWiaQ48Va2YV-lmcEo3hYpDQR4irKAo2qST01Ir20yAIjwvwMz_=s100-p-k-no-mo"
        }
      ],
      "flagContentUri": "https://www.google.com/local/imagery/report/?cb_client=maps_api_places.places_api&image_key=!1e10!2sCIHM0ogKEICAgIDzqrytOg&hl=en-US",
      "googleMapsUri": "https://www.google.com/maps/place//data=!3m4!1e2!3m2!1sCIHM0ogKEICAgIDzqrytOg!2e10!4m2!3m1!1s0x6b12ae246c85078d:0x318bc7d63e0a25f7"
    },
    {
      "name": "places/ChIJjQeFbCSuEmsR9yUKPtbHizE/photos/AUy1YQ2xycUqCxfd4rf7tdw37arhggd_dxJkcDtM8AU3kZuxRWNreDPRj_JJ8Jj81QYmQoSJp07rhHKmDGaRVnUYgVdWldejQYJuaqmXUPVD46DHUvPAU1Y_azWQDR6Tqzdory9sGtL7WbsIYNFte0oMMRCio_9gcaNO4LQ3RRZG7eAGU7iTLMIteuQtKyLWb61xwg0gW6aJUIdBnk6Q-8RB7ZTkruGta234LS72QDbftclIVx4toO9LzAUCwC6aUiRbEprEr8wlXGHfGofVsDSNJuC4H6sPLuPkyhPBkhVOemP5l9Q-04dGdP3SPAYgv7iZeG4GNEPrPHl9YcTQhgZvvkW3C83fFVYUOObfqRJlL4C8QQ39qM3V31AkISIUEwntVJ0EY7jzE-310Au1lqo_QR3mwT9cVwcUSS8F",
      "widthPx": 3072,
      "heightPx": 4080,
      "authorAttributions": [
        {
          "displayName": "John Scolaro",
          "uri": "https://maps.google.com/maps/contrib/113054639780181758122",
          "photoUri": "https://lh3.googleusercontent.com/a-/ALV-UjXu3mNjpg_HHDOhFd0VszST_1yT4kRFOjgDBD6Y-ZXSk5WoTEqLtg=s100-p-k-no-mo"
        }
      ],
      "flagContentUri": "https://www.google.com/local/imagery/report/?cb_client=maps_api_places.places_api&image_key=!1e10!2sCIHM0ogKEICAgICLz__2nwE&hl=en-US",
      "googleMapsUri": "https://www.google.com/maps/place//data=!3m4!1e2!3m2!1sCIHM0ogKEICAgICLz__2nwE!2e10!4m2!3m1!1s0x6b12ae246c85078d:0x318bc7d63e0a25f7"
    },
    {
      "name": "places/ChIJjQeFbCSuEmsR9yUKPtbHizE/photos/AUy1YQ1ECQWQvBScI4pyAryoxOxDgiyGMhA0_fN-dfhMnaXUqmAayFzgKhIhFlcB-AAyNCSQaw4nxr02KWxgW9DZAIcarlNm5Fzzkckt7QLCJZFiRU30OShbUkVSZ-or2BvJCRbiksQtBI8ztAkw8lHApJGkaL45k9neuJgwkBUf8TL7xEj3ter_1K-C79t1G6W6cVrSlEUOg8E9eRortzO_0blRc5z-i4omxPgOxqP9XDg8fLcCTGnmdnH1WdbUce-YMMawice8gdE5EqTP2taNEErJBv30lD8Fw9X7OpFD_PanHxUB3qOclrrwcy4wa4_Q9Wlk4o4mBEho_87lwv6rmh5pyWF_QDSGc3mu9Zn9uqEI3ekWu4FSD7hXYQbNvdsKpGAengOjjPTVtXFtPgGXPzXxl_40r98",
      "widthPx": 2992,
      "heightPx": 2992,
      "authorAttributions": [
        {
          "displayName": "Jessie",
          "uri": "https://maps.google.com/maps/contrib/101364589625275895384",
          "photoUri": "https://lh3.googleusercontent.com/a/ACg8ocLU6CmanRkKnkE41XB5TkzkfN9ma4UzvN4aqvfG2zVSutlgTg=s100-p-k-no-mo"
        }
      ],
      "flagContentUri": "https://www.google.com/local/imagery/report/?cb_client=maps_api_places.places_api&image_key=!1e10!2sCIHM0ogKEICAgIC_2tikEw&hl=en-US",
      "googleMapsUri": "https://www.google.com/maps/place//data=!3m4!1e2!3m2!1sCIHM0ogKEICAgIC_2tikEw!2e10!4m2!3m1!1s0x6b12ae246c85078d:0x318bc7d63e0a25f7"
    },
    {
      "name": "places/ChIJjQeFbCSuEmsR9yUKPtbHizE/photos/AUy1YQ0WX3ABZfM2h-MvkY4lgYu26SnMpzmVvnncQwgWf_9H2l3G7qGWFETstEXxj3K8eo799IToIgea9nBVg0ZSOhf_x6Gkgfwo-sZdDQIj5ygwyP-UC_VkHzV3lKWurSKrx2IW_agC4BNzE5KTOUuADTYEaLEyPY-K0LZJp_vyDumQtBA6a94aE86k_gupZ7mfOtwAMD3VBgidJ_j9fANBLqOJ7GGmn3q-5TH9rYpp1w-f0_EuPGeUjxpn3TMGcoRndJPlL36IKiw-y2tfliDWf5fPsCbz1egYfLIBh6B3uRtnocXBCHpgRCC8Zcc-v6GBAaTIMsGOaLCUSxdHsK3YnY7osjxCqfEf2nZWXoHoryDxGb2AjVhQ0W8lVzBvuVkB2zbNygt4wFuXBqIQuGgg4voslCFrOtX5",
      "widthPx": 3862,
      "heightPx": 2896,
      "authorAttributions": [
        {
          "displayName": "zoey",
          "uri": "https://maps.google.com/maps/contrib/112967713476973642713",
          "photoUri": "https://lh3.googleusercontent.com/a/ACg8ocIIjXDUj73S24a-k6rcz1gEL1CJBDgvvEHNFIB2Ng3UAdQjtA=s100-p-k-no-mo"
        }
      ],
      "flagContentUri": "https://www.google.com/local/imagery/report/?cb_client=maps_api_places.places_api&image_key=!1e10!2sCIHM0ogKEICAgMCA9_7kaA&hl=en-US",
      "googleMapsUri": "https://www.google.com/maps/place//data=!3m4!1e2!3m2!1sCIHM0ogKEICAgMCA9_7kaA!2e10!4m2!3m1!1s0x6b12ae246c85078d:0x318bc7d63e0a25f7"
    },
    {
      "name": "places/ChIJjQeFbCSuEmsR9yUKPtbHizE/photos/AUy1YQ2_M5YitzvXDFXrMD8d8oSi_V_RKjzEuF3S3lTtuqs4AvfR67_6l1P_UVikevomfMKu-WTb_7L6OJu1HFYhT7usWONFZ2T3De_Wjv7bRfTswXF5_Yj-l6FiG9RR27Ewx4o2qFYZYQJn6ZF-lykrvWw4_hO77iKl_9ag-Nskn7guF9kWQv1ersTceAbqT34WZ2eo9Kbfu7bxoWJE2-KKiLDQbBYQtuWyl9mHSatG7uHfwKIgnu0sMV4cAgtitXBNT2o72wGIRVPliZE8o52IGF52aLN4qbb0oPnmCl8tY5BfrH-Sd43Z1wGA76EfsCZy1maGIbR8ZCq136vCIvnZ4LHs1ZRUMHYxY9cFJ-nnAzKcTWWan9Mr0nfqJq84QMLNPFB0nh7mwXzd4wYTWiS_NwWh9WSSwaeKZNLv",
      "widthPx": 4032,
      "heightPx": 3024,
      "authorAttributions": [
        {
          "displayName": "Brandon Lim",
          "uri": "https://maps.google.com/maps/contrib/116062387748105811668",
          "photoUri": "https://lh3.googleusercontent.com/a/ACg8ocLkjGRFVE7Twb-XeO5GgDq_WK_RK5td53-v_ZPrMdP6LMYuYw=s100-p-k-no-mo"
        }
      ],
      "flagContentUri": "https://www.google.com/local/imagery/report/?cb_client=maps_api_places.places_api&image_key=!1e10!2sCIHM0ogKEICAgIDDiOuqrQE&hl=en-US",
      "googleMapsUri": "https://www.google.com/maps/place//data=!3m4!1e2!3m2!1sCIHM0ogKEICAgIDDiOuqrQE!2e10!4m2!3m1!1s0x6b12ae246c85078d:0x318bc7d63e0a25f7"
    },
    {
      "name": "places/ChIJjQeFbCSuEmsR9yUKPtbHizE/photos/AUy1YQ2gj5ULTwLPlr1XZwJBOTN1R_IlG5pFMcfgt9zv7EWU-21IsVJ2Jt4vdhWokrfQECr0u6iwB0hDphmshgrEJ7MxjLQxfVfDl8XBZXGZpwYSurEc78UArSdwVHcUrfo6GvaAhh3uEr4Q6RDHRS5wOCA9sjulTL46gWHLj30zR1vddkxDtnsmT2iNJpxE2pmrGns8z2Fq4ZtqW_RrluHJv-xxepbeh1dPOY9NWOgR0XbLbXGvcfIe_XZRNz-4HpMCrcSq-yqNoQQDVPDeimtoc-7F3CGIjPy5VFrmo1niliktvA8TYhtwBRCcf2yQ3roK2jJQjcnAlunuedm6AXFS1dVXOv2fVwCbC-KN_CtWohrm7cqqA-SpPGWBi5rhM51AVGM0XhpXv0skZKG5tjffY3l4IBafGOpm",
      "widthPx": 4032,
      "heightPx": 3024,
      "authorAttributions": [
        {
          "displayName": "Brandon Lim",
          "uri": "https://maps.google.com/maps/contrib/116062387748105811668",
          "photoUri": "https://lh3.googleusercontent.com/a/ACg8ocLkjGRFVE7Twb-XeO5GgDq_WK_RK5td53-v_ZPrMdP6LMYuYw=s100-p-k-no-mo"
        }
      ],
      "flagContentUri": "https://www.google.com/local/imagery/report/?cb_client=maps_api_places.places_api&image_key=!1e10!2sCIHM0ogKEICAgIDDuJ3xXQ&hl=en-US",
      "googleMapsUri": "https://www.google.com/maps/place//data=!3m4!1e2!3m2!1sCIHM0ogKEICAgIDDuJ3xXQ!2e10!4m2!3m1!1s0x6b12ae246c85078d:0x318bc7d63e0a25f7"
    }
  ]
}